@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');


.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal) .layout-page:before {
    content: "";
    width: 100%;
    height: 0.75rem;
    position: fixed;
    top: 0px;
    z-index: 10;
}

.bg-menu-theme .menu-header {
    position: relative;
}

.bg-menu-theme .menu-header:before {
    content: "";
    position: absolute;
    left: 0;
    top: 1.1875rem;
    width: 1rem;
    height: 1px;
    transition: all 0.3s ease-in-out;
}

@media (min-width: 1200px) {
    .layout-horizontal .bg-menu-theme .menu-inner>.menu-item {
        margin: 0.565rem 0;
    }
    .layout-horizontal .bg-menu-theme .menu-inner>.menu-item:not(:first-child) {
        margin-left: 0.0625rem;
    }
    .layout-horizontal .bg-menu-theme .menu-inner>.menu-item:not(:last-child) {
        margin-right: 0.0625rem;
    }
    .layout-horizontal .bg-menu-theme .menu-inner>.menu-item .menu-sub {
        box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, 0.45);
    }
}

.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner>.menu-item.active:before {
    content: "";
    position: absolute;
    left: 0;
    width: 0.25rem;
    height: 2.7rem;
}

.bg-menu-theme .menu-sub>.menu-item>.menu-link:before {
    content: "";
    position: absolute;
    left: 1.4375rem;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
}

.layout-horizontal .bg-menu-theme .menu-sub>.menu-item>.menu-link:before {
    left: 1.3rem;
}

.bg-menu-theme .menu-horizontal-wrapper>.menu-inner>.menu-item>.menu-sub>.menu-item>.menu-link:before {
    display: none;
}

.bg-menu-theme .menu-sub>.menu-item.active>.menu-link:not(.menu-toggle):before {
    left: 1.1875rem;
    width: 0.875rem;
    height: 0.875rem;
}

.layout-horizontal .bg-menu-theme .menu-sub>.menu-item.active>.menu-link:not(.menu-toggle):before {
    left: 1.1rem;
}

.layout-menu-hover .layout-menu {
    box-shadow: 0 0.625rem 1.25rem rgba(161, 172, 184, 0.5);
    transition: all 0.3s ease-in-out;
}

.app-brand .layout-menu-toggle {
    position: absolute;
    left: 15rem;
    border-radius: 50%;
}

.app-brand .layout-menu-toggle i {
    width: 1.5rem;
    height: 1.5rem;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1199.98px) {
    .app-brand .layout-menu-toggle {
        display: none !important;
    }
    .layout-menu-expanded .app-brand .layout-menu-toggle {
        display: block !important;
    }
}

.text-primary {
    color: #26385a !important;
}

.text-body[href]:hover {
    color: #006eff !important;
}

.bg-primary {
    background-color: #006eff !important;
}

a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #6467f2 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
    background-color: #006eff;
}

.bg-label-primary {
    background-color: #e7e7ff !important;
    color: #006eff !important;
}

.border-label-primary {
    border: 3px solid #c3c4ff !important;
}

.border-light-primary {
    border: 3px solid rgba(105, 108, 255, 0.08);
}

.page-item.active .page-link,
.page-item.active .page-link:hover,
.page-item.active .page-link:focus,
.pagination li.active>a:not(.page-link),
.pagination li.active>a:not(.page-link):hover,
.pagination li.active>a:not(.page-link):focus {
    border-color: #006eff;
    background-color: #006eff;
    color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(105, 108, 255, 0.4);
}

.progress-bar {
    background-color: #006eff;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgba(105, 108, 255, 0.4);
}

.list-group-item-primary {
    background-color: #e1e2ff;
    color: #006eff !important;
}

a.list-group-item-primary,
button.list-group-item-primary {
    color: #006eff;
}

a.list-group-item-primary:hover,
a.list-group-item-primary:focus,
button.list-group-item-primary:hover,
button.list-group-item-primary:focus {
    background-color: #d6d7f2;
    color: #006eff;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
    border-color: #006eff;
    background-color: #006eff;
    color: #006eff;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
    border-color: #006eff;
    background-color: #006eff;
}

.alert-primary {
    background-color: #e7e7ff;
    border-color: #d2d3ff;
    color: #006eff;
}

.alert-primary .btn-close {
    background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23006eff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23006eff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.alert-primary .alert-link {
    color: #006eff;
}

.card .alert-primary hr {
    background-color: #006eff !important;
}

.table-primary {
    --bs-table-bg: #e1e2ff;
    --bs-table-striped-bg: #dcdefb;
    --bs-table-striped-color: #435971;
    --bs-table-active-bg: #d1d4f1;
    --bs-table-active-color: #435971;
    --bs-table-hover-bg: #d8daf6;
    --bs-table-hover-color: #435971;
    color: #435971;
    border-color: #d1d4f1;
}

.table-primary th {
    border-bottom-color: inherit !important;
}

.table-primary .btn-icon {
    color: #435971;
}

.btn-primary {
    color: #fff;
    background-color: #006eff;
    border-color: #006eff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #006eff;
    border-color: #006eff;
    transform: translateY(-1px);
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #006eff;
    border-color: #006eff;
    transform: translateY(0);
    box-shadow: none;
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #006eff;
    border-color: #006eff;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
    box-shadow: none;
}

.btn-outline-primary {
    color: #006eff;
    border-color: #006eff;
    background: transparent;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #006eff;
    border-color: #006eff;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, 0.4);
    transform: translateY(-1px);
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    color: #fff;
    background-color: #006eff;
    border-color: #006eff;
    box-shadow: none;
    transform: translateY(0);
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #006eff;
    border-color: #006eff;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    box-shadow: none;
}

.btn-outline-primary .badge {
    background: #006eff;
    border-color: #006eff;
    color: #fff;
}

.btn-outline-primary:hover .badge,
.btn-outline-primary:focus:hover .badge,
.btn-outline-primary:active .badge,
.btn-outline-primary.active .badge,
.show>.btn-outline-primary.dropdown-toggle .badge {
    background: #fff;
    border-color: #fff;
    color: #006eff;
}

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
    background-color: rgba(105, 108, 255, 0.08);
    color: #006eff !important;
}

.dropdown-menu>li:not(.disabled)>a:not(.dropdown-item):active,
.dropdown-menu>li.active:not(.disabled)>a:not(.dropdown-item) {
    background-color: rgba(105, 108, 255, 0.08);
    color: #006eff !important;
}

.nav .nav-link:hover,
.nav .nav-link:focus {
    color: #006eff;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
    background-color: #006eff;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgba(105, 108, 255, 0.4);
}

.form-control:focus,
.form-select:focus {
    border-color: #006eff;
}

.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text {
    border-color: #006eff;
}

.form-check-input:focus {
    border-color: #006eff;
    box-shadow: 0 2px 4px 0 rgba(105, 108, 255, 0.4);
}

.form-check-input:disabled {
    background-color: #eceef1;
}

.form-check-input:checked,
.form-check-input[type=checkbox]:indeterminate {
    background-color: #006eff;
    border-color: #006eff;
    box-shadow: 0 2px 4px 0 rgba(105, 108, 255, 0.4);
}

.custom-option.checked {
    border: 1px solid #006eff;
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23006eff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-control:focus~.form-label {
    border-color: #006eff;
}

.form-control:focus~.form-label::after {
    border-color: inherit;
}

.divider.divider-primary .divider-text:before,
.divider.divider-primary .divider-text:after {
    border-color: #006eff;
}

.navbar.bg-primary {
    background-color: #006eff !important;
    color: #e0e1ff;
}

.navbar.bg-primary .navbar-brand,
.navbar.bg-primary .navbar-brand a {
    color: #fff;
}

.navbar.bg-primary .navbar-brand:hover,
.navbar.bg-primary .navbar-brand:focus,
.navbar.bg-primary .navbar-brand a:hover,
.navbar.bg-primary .navbar-brand a:focus {
    color: #fff;
}

.navbar.bg-primary .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-primary .navbar-search-wrapper .search-input {
    color: #e0e1ff;
}

.navbar.bg-primary .search-input-wrapper .search-input,
.navbar.bg-primary .search-input-wrapper .search-toggler {
    background-color: #006eff !important;
    color: #e0e1ff;
}

.navbar.bg-primary .navbar-nav>.nav-link,
.navbar.bg-primary .navbar-nav>.nav-item>.nav-link,
.navbar.bg-primary .navbar-nav>.nav>.nav-item>.nav-link {
    color: #e0e1ff;
}

.navbar.bg-primary .navbar-nav>.nav-link:hover,
.navbar.bg-primary .navbar-nav>.nav-link:focus,
.navbar.bg-primary .navbar-nav>.nav-item>.nav-link:hover,
.navbar.bg-primary .navbar-nav>.nav-item>.nav-link:focus,
.navbar.bg-primary .navbar-nav>.nav>.nav-item>.nav-link:hover,
.navbar.bg-primary .navbar-nav>.nav>.nav-item>.nav-link:focus {
    color: #fff;
}

.navbar.bg-primary .navbar-nav>.nav-link.disabled,
.navbar.bg-primary .navbar-nav>.nav-item>.nav-link.disabled,
.navbar.bg-primary .navbar-nav>.nav>.nav-item>.nav-link.disabled {
    color: #b0b2ff !important;
}

.navbar.bg-primary .navbar-nav .show>.nav-link,
.navbar.bg-primary .navbar-nav .active>.nav-link,
.navbar.bg-primary .navbar-nav .nav-link.show,
.navbar.bg-primary .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar.bg-primary .navbar-toggler {
    color: #e0e1ff;
    border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-primary .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M0,0 L14,0 L14,1.75 L0,1.75 L0,0 Z M0,4.375 L14,4.375 L14,6.125 L0,6.125 L0,4.375 Z M0,8.75 L14,8.75 L14,10.5 L0,10.5 L0,8.75 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='💎-UI-Elements' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='12)-Navbar' transform='translate(-1174.000000, -1290.000000)'%3E%3Cg id='Group' transform='translate(1174.000000, 1288.000000)'%3E%3Cg id='Icon-Color' transform='translate(0.000000, 2.000000)'%3E%3Cuse fill='rgba(255, 255, 255, 0.8)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.1' fill='rgba(255, 255, 255, 0.8)' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.navbar.bg-primary .navbar-text {
    color: #e0e1ff;
}

.navbar.bg-primary .navbar-text a {
    color: #fff;
}

.navbar.bg-primary .navbar-text a:hover,
.navbar.bg-primary .navbar-text a:focus {
    color: #fff;
}

.navbar.bg-primary hr {
    border-color: rgba(255, 255, 255, 0.15);
}

.menu.bg-primary {
    background-color: #006eff !important;
    color: #e0e1ff;
}

.menu.bg-primary .menu-link,
.menu.bg-primary .menu-horizontal-prev,
.menu.bg-primary .menu-horizontal-next {
    color: #e0e1ff;
}

.menu.bg-primary .menu-link:hover,
.menu.bg-primary .menu-link:focus,
.menu.bg-primary .menu-horizontal-prev:hover,
.menu.bg-primary .menu-horizontal-prev:focus,
.menu.bg-primary .menu-horizontal-next:hover,
.menu.bg-primary .menu-horizontal-next:focus {
    color: #fff;
}

.menu.bg-primary .menu-link.active,
.menu.bg-primary .menu-horizontal-prev.active,
.menu.bg-primary .menu-horizontal-next.active {
    color: #fff;
}

.menu.bg-primary .menu-item.disabled .menu-link,
.menu.bg-primary .menu-horizontal-prev.disabled,
.menu.bg-primary .menu-horizontal-next.disabled {
    color: #b0b2ff !important;
}

.menu.bg-primary .menu-item.open:not(.menu-item-closing)>.menu-toggle,
.menu.bg-primary .menu-item.active>.menu-link {
    color: #fff;
}

.menu.bg-primary .menu-item.active>.menu-link:not(.menu-toggle) {
    background-color: #6d70ff;
}

.menu.bg-primary.menu-horizontal .menu-sub>.menu-item.active>.menu-link:not(.menu-toggle) {
    background-color: #7174ff;
}

.menu.bg-primary.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing)>.menu-sub,
.menu.bg-primary.menu-horizontal .menu-inner .menu-item.open>.menu-toggle {
    background: #6d70ff;
}

.menu.bg-primary .menu-inner>.menu-item.menu-item-closing .menu-item.open .menu-sub,
.menu.bg-primary .menu-inner>.menu-item.menu-item-closing .menu-item.open .menu-toggle {
    background: transparent;
    color: #e0e1ff;
}

.menu.bg-primary .menu-inner-shadow {
    background: linear-gradient(#006eff 41%, rgba(105, 108, 255, 0.11) 95%, rgba(105, 108, 255, 0));
}

.menu.bg-primary .menu-text {
    color: #fff;
}

.menu.bg-primary .menu-header {
    color: #c2c4ff;
}

.menu.bg-primary hr,
.menu.bg-primary .menu-divider,
.menu.bg-primary .menu-inner>.menu-item.open>.menu-sub::before {
    border-color: rgba(255, 255, 255, 0.15) !important;
}

.menu.bg-primary .menu-inner>.menu-header::before {
    background-color: rgba(255, 255, 255, 0.15);
}

.menu.bg-primary .menu-block::before {
    background-color: #c2c4ff;
}

.menu.bg-primary .menu-inner>.menu-item.open .menu-item.open>.menu-toggle::before {
    background-color: #8385ff;
}

.menu.bg-primary .menu-inner>.menu-item.open .menu-item.active>.menu-link::before {
    background-color: #fff;
}

.menu.bg-primary .menu-inner>.menu-item.open .menu-item.open>.menu-toggle::before,
.menu.bg-primary .menu-inner>.menu-item.open .menu-item.active>.menu-link::before {
    box-shadow: 0 0 0 2px #6d70ff;
}

.menu.bg-primary .ps__thumb-y,
.menu.bg-primary .ps__rail-y.ps--clicking>.ps__thumb-y {
    background: rgba(255, 255, 255, 0.5942917647) !important;
}

.footer.bg-primary {
    background-color: #006eff !important;
    color: #e0e1ff;
}

.footer.bg-primary .footer-link {
    color: #e0e1ff;
}

.footer.bg-primary .footer-link:hover,
.footer.bg-primary .footer-link:focus {
    color: #fff;
}

.footer.bg-primary .footer-link.disabled {
    color: #b0b2ff !important;
}

.footer.bg-primary .footer-text {
    color: #fff;
}

.footer.bg-primary .show>.footer-link,
.footer.bg-primary .active>.footer-link,
.footer.bg-primary .footer-link.show,
.footer.bg-primary .footer-link.active {
    color: #fff;
}

.footer.bg-primary hr {
    border-color: rgba(255, 255, 255, 0.15);
}

.bg-primary.toast,
.bg-primary.bs-toast {
    color: #fff;
    background-color: rgba(105, 108, 255, 0.85) !important;
    box-shadow: 0 0.25rem 1rem rgba(105, 108, 255, 0.4);
}

.bg-primary.toast .toast-header,
.bg-primary.bs-toast .toast-header {
    color: #fff;
}

.bg-primary.toast .toast-header .btn-close,
.bg-primary.bs-toast .toast-header .btn-close {
    background-color: #006eff !important;
    background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(105, 108, 255, 0.4) !important;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    color: #006eff;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: #006eff;
}

.form-floating>.form-control:-webkit-autofill~label {
    color: #006eff;
}

.svg-illustration svg {
    fill: #006eff;
}

html:not([dir=rtl]) .border-primary,
html[dir=rtl] .border-primary {
    border-color: #006eff !important;
}

a {
    color: #006eff;
}

a:hover {
    color: #787bff;
}

.fill-primary {
    fill: #006eff;
}

.bg-navbar-theme {
    background-color: #fff !important;
    color: #f5f5f9;
}

.bg-navbar-theme .navbar-brand,
.bg-navbar-theme .navbar-brand a {
    color: #566a7f;
}

.bg-navbar-theme .navbar-brand:hover,
.bg-navbar-theme .navbar-brand:focus,
.bg-navbar-theme .navbar-brand a:hover,
.bg-navbar-theme .navbar-brand a:focus {
    color: #566a7f;
}

.bg-navbar-theme .navbar-search-wrapper .navbar-search-icon,
.bg-navbar-theme .navbar-search-wrapper .search-input {
    color: #697a8d;
}

.bg-navbar-theme .search-input-wrapper .search-input,
.bg-navbar-theme .search-input-wrapper .search-toggler {
    background-color: #fff !important;
    color: #697a8d;
}

.bg-navbar-theme .navbar-nav>.nav-link,
.bg-navbar-theme .navbar-nav>.nav-item>.nav-link,
.bg-navbar-theme .navbar-nav>.nav>.nav-item>.nav-link {
    color: #697a8d;
}

.bg-navbar-theme .navbar-nav>.nav-link:hover,
.bg-navbar-theme .navbar-nav>.nav-link:focus,
.bg-navbar-theme .navbar-nav>.nav-item>.nav-link:hover,
.bg-navbar-theme .navbar-nav>.nav-item>.nav-link:focus,
.bg-navbar-theme .navbar-nav>.nav>.nav-item>.nav-link:hover,
.bg-navbar-theme .navbar-nav>.nav>.nav-item>.nav-link:focus {
    color: #566a7f;
}

.bg-navbar-theme .navbar-nav>.nav-link.disabled,
.bg-navbar-theme .navbar-nav>.nav-item>.nav-link.disabled,
.bg-navbar-theme .navbar-nav>.nav>.nav-item>.nav-link.disabled {
    color: #a5afbb !important;
}

.bg-navbar-theme .navbar-nav .show>.nav-link,
.bg-navbar-theme .navbar-nav .active>.nav-link,
.bg-navbar-theme .navbar-nav .nav-link.show,
.bg-navbar-theme .navbar-nav .nav-link.active {
    color: #566a7f;
}

.bg-navbar-theme .navbar-toggler {
    color: #697a8d;
    border-color: rgba(86, 106, 127, 0.075);
}

.bg-navbar-theme .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M0,0 L14,0 L14,1.75 L0,1.75 L0,0 Z M0,4.375 L14,4.375 L14,6.125 L0,6.125 L0,4.375 Z M0,8.75 L14,8.75 L14,10.5 L0,10.5 L0,8.75 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='💎-UI-Elements' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='12)-Navbar' transform='translate(-1174.000000, -1290.000000)'%3E%3Cg id='Group' transform='translate(1174.000000, 1288.000000)'%3E%3Cg id='Icon-Color' transform='translate(0.000000, 2.000000)'%3E%3Cuse fill='rgba(67, 89, 113, 0.5)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.1' fill='rgba(67, 89, 113, 0.5)' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.bg-navbar-theme .navbar-text {
    color: #697a8d;
}

.bg-navbar-theme .navbar-text a {
    color: #566a7f;
}

.bg-navbar-theme .navbar-text a:hover,
.bg-navbar-theme .navbar-text a:focus {
    color: #566a7f;
}

.bg-navbar-theme hr {
    border-color: rgba(86, 106, 127, 0.075);
}

.layout-navbar {
    background-color: #131523 !important;
    -webkit-backdrop-filter: saturate(200%) blur(6px);
    backdrop-filter: saturate(200%) blur(6px);
}

.layout-navbar-fixed .layout-page:before {
    -webkit-backdrop-filter: saturate(200%) blur(10px);
    backdrop-filter: saturate(200%) blur(10px);
    background: rgba(245, 245, 249, 0.6);
}

.bg-menu-theme {
    background-color: #fff !important;
    color: #697a8d;
}

.bg-menu-theme .menu-link,
.bg-menu-theme .menu-horizontal-prev,
.bg-menu-theme .menu-horizontal-next {
    color: #697a8d;
}

.bg-menu-theme .menu-link:hover,
.bg-menu-theme .menu-link:focus,
.bg-menu-theme .menu-horizontal-prev:hover,
.bg-menu-theme .menu-horizontal-prev:focus,
.bg-menu-theme .menu-horizontal-next:hover,
.bg-menu-theme .menu-horizontal-next:focus {
    color: #566a7f;
}

.bg-menu-theme .menu-link.active,
.bg-menu-theme .menu-horizontal-prev.active,
.bg-menu-theme .menu-horizontal-next.active {
    color: #566a7f;
}

.bg-menu-theme .menu-item.disabled .menu-link,
.bg-menu-theme .menu-horizontal-prev.disabled,
.bg-menu-theme .menu-horizontal-next.disabled {
    color: #a5afbb !important;
}

.bg-menu-theme .menu-item.open:not(.menu-item-closing)>.menu-toggle,
.bg-menu-theme .menu-item.active>.menu-link {
    color: #566a7f;
}

.bg-menu-theme .menu-item.active>.menu-link:not(.menu-toggle) {
    background-color: #fff;
}

.bg-menu-theme.menu-horizontal .menu-sub>.menu-item.active>.menu-link:not(.menu-toggle) {
    background-color: white;
}

.bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing)>.menu-sub,
.bg-menu-theme.menu-horizontal .menu-inner .menu-item.open>.menu-toggle {
    background: #fff;
}

.bg-menu-theme .menu-inner>.menu-item.menu-item-closing .menu-item.open .menu-sub,
.bg-menu-theme .menu-inner>.menu-item.menu-item-closing .menu-item.open .menu-toggle {
    background: transparent;
    color: #697a8d;
}

.bg-menu-theme .menu-inner-shadow {
    background: linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}

.bg-menu-theme .menu-text {
    color: #566a7f;
}

.bg-menu-theme .menu-header {
    color: #8f9baa;
}

.bg-menu-theme hr,
.bg-menu-theme .menu-divider,
.bg-menu-theme .menu-inner>.menu-item.open>.menu-sub::before {
    border-color: transparent !important;
}

.bg-menu-theme .menu-inner>.menu-header::before {
    background-color: transparent;
}

.bg-menu-theme .menu-block::before {
    background-color: #8f9baa;
}

.bg-menu-theme .menu-inner>.menu-item.open .menu-item.open>.menu-toggle::before {
    background-color: white;
}

.bg-menu-theme .menu-inner>.menu-item.open .menu-item.active>.menu-link::before {
    background-color: #566a7f;
}

.bg-menu-theme .menu-inner>.menu-item.open .menu-item.open>.menu-toggle::before,
.bg-menu-theme .menu-inner>.menu-item.open .menu-item.active>.menu-link::before {
    box-shadow: 0 0 0 2px #fff;
}

.bg-menu-theme .ps__thumb-y,
.bg-menu-theme .ps__rail-y.ps--clicking>.ps__thumb-y {
    background: rgba(86, 106, 127, 0.2) !important;
}

.bg-menu-theme .menu-header {
    color: #a1acb8;
}

.bg-menu-theme .menu-header:before {
    background-color: #a1acb8 !important;
}

.bg-menu-theme.menu-vertical {
    box-shadow: 0 0.125rem 0.375rem 0 rgba(161, 172, 184, 0.12);
}

html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item.open>.menu-link,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item.open>.menu-link,
html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover {
    background-color: rgba(67, 89, 113, 0.04);
}

.bg-menu-theme .menu-inner .menu-sub>.menu-item.active>.menu-link.menu-toggle {
    background-color: rgba(67, 89, 113, 0.04);
}

.bg-menu-theme .menu-inner .menu-sub>.menu-item.active .menu-icon {
    color: #006eff;
}

.bg-menu-theme .menu-inner>.menu-item.active>.menu-link {
    color: #006eff;
    background-color: rgba(0, 88, 255, 0.16) !important;
}

.bg-menu-theme .menu-inner>.menu-item.active:before {
    background: #006eff;
}

.bg-menu-theme .menu-sub>.menu-item>.menu-link:before {
    background-color: #b4bdc6 !important;
}

.bg-menu-theme .menu-sub>.menu-item.active>.menu-link:not(.menu-toggle):before {
    background-color: #006eff !important;
    border: 3px solid #e7e7ff !important;
}

.app-brand .layout-menu-toggle {
    background-color: #ffffff;
    border: 7px solid #ffffff;
}

.app-brand .layout-menu-toggle i {
    color: #fff;
}

.app-brand .layout-menu-toggle .menu-inner>.menu-header::before {
    background-color: #b4bdc6;
}

.bg-footer-theme {
    background-color: #f5f5f9 !important;
    color: #697a8d;
}

.bg-footer-theme .footer-link {
    color: #697a8d;
}

.bg-footer-theme .footer-link:hover,
.bg-footer-theme .footer-link:focus {
    color: #566a7f;
}

.bg-footer-theme .footer-link.disabled {
    color: #a1abb8 !important;
}

.bg-footer-theme .footer-text {
    color: #566a7f;
}

.bg-footer-theme .show>.footer-link,
.bg-footer-theme .active>.footer-link,
.bg-footer-theme .footer-link.show,
.bg-footer-theme .footer-link.active {
    color: #566a7f;
}

.bg-footer-theme hr {
    border-color: rgba(86, 106, 127, 0.0768713725);
}

.layout-footer-fixed .content-footer {
    box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
}

.arrow {
    display: inline-block;
    font-family: "Nanum Gothic";
    padding: 0 2px 0 4px;
    transform: translateY(-1px);
}